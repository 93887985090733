import React from 'react';
import { Grid } from '@mui/material';
import BinanceBrokerImages from '../BinanceBrokerImages';
import FireblocksImage from '../FireblocksImage';

export const SealImagesBlock = () => {
  return (
    <Grid
      item
      container
      alignItems={'center'}
      justifyContent={'center'}
      gap={'8px'}
      sx={{ opacity: 0.7 }}
    >
      <BinanceBrokerImages />
      <FireblocksImage />
    </Grid>
  );
};

export default SealImagesBlock;
